import Vue from 'vue'
import VueRouter from 'vue-router'
// 导入组件
import home from '@/views/home'
import product from '@/views/product'
import News from '@/views/News'


Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/home' },
  { name: 'home', path: '/home', component: home },
  { name: 'product', path: '/product', component: product },
  { name: 'News', path: '/News', component: News }
]

const router = new VueRouter({
  routes
})

export default router
