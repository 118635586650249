<template>
  <div class="wrapper" style="background-color: #f8f6f9">
    <el-row class="header">
      <el-col>
        <div class="left">
          <img src="../../assets/logo.png" alt="" />
        </div>
      </el-col>
      <el-col class="header_right">
        <div class="box2 active">
          <div class="text">
            <div class="top">首页</div>
            <div class="bott">Home</div>
          </div>
          <div class="bj"></div>
        </div>
        <div class="box2 products">
          <div class="text">
            <div class="top">产品介绍</div>
            <div class="bott">Products</div>
          </div>
          <div class="bj"></div>
        </div>
        <div class="box2 news">
          <div class="text">
            <div class="top">新闻动态</div>
            <div class="bott">News</div>
          </div>
          <div class="bj"></div>
        </div>
        <div class="box2 purchase">
          <div class="text">
            <div class="top">如何购买</div>
            <div class="bott">Purchase</div>
          </div>
          <div class="bj"></div>
        </div>
        <div class="box2 about">
          <div class="text">
            <div class="top">关于我们</div>
            <div class="bott">About us</div>
          </div>
          <div class="bj"></div>
        </div>
      </el-col>
    </el-row>
    <el-carousel
      type="card"
      trigger="click"
      class="banner"
      :autoplay="true"
      :interval="4000"
      arrow="always"
      @change="carouselChange"
    >
      <el-carousel-item
        v-for="item in imgList"
        :key="item.id"
        ref="carousel"
        :id="item.id"
      >
        <img :src="item.idView" class="lbt" />
      </el-carousel-item>
    </el-carousel>
    <!-- 产品区域 -->
    <div class="cp">
      <el-row>
        <el-col :span="24">
          <div class="cpxxx">
            <h5 class="grid-content">产品介绍</h5>
          </div>
        </el-col>
      </el-row>
      <!-- 三个个盒子 -->
      <el-row class="sm">
        <el-col :span="6">
          <div class="grid-content1" @click="cpxqy">
            <img src="../../assets/cheshi.png" alt="" />
            <div class="xwbt">标题</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content1" @click="cpxqy">
            <img src="../../assets/cheshi.png" alt="" />
            <div class="xwbt">标题</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content1" @click="cpxqy">
            <img src="../../assets/cheshi.png" alt="" />
            <div class="xwbt">标题</div>
          </div>
        </el-col>
      </el-row>
      <el-row class="sm1">
        <el-col :span="6">
          <div class="grid-content2" @click="cpxqy">
            <img src="../../assets/cheshi.png" alt="" />
            <div class="xwbt">标题</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content2 cptop" @click="cpxqy">
            <img src="../../assets/cheshi.png" alt="" />
            <div class="xwbt">标题</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content2" @click="cpxqy">
            <img src="../../assets/cheshi.png" alt="" />
            <div class="xwbt">标题</div>
          </div>
        </el-col>
      </el-row>
      <!-- 新闻区域 -->
      <div class="xw">
        <el-row>
          <el-col :span="24">
            <div class="cpxxx">
              <h5 class="grid-content">最新动态</h5>
            </div>
          </el-col>
        </el-row>
        <!-- 三个个盒子 -->
        <el-row class="xm">
          <el-col :span="6">
            <div class="grid-content3" @click="xwxqy">
              <img src="../../assets/cheshi.png" alt="" />
              <div class="name"><span>仙剑奇侠传</span></div>
              <div class="sell">仙剑四主题卡组限量发售</div>
              <div class="brief">
                仙剑4第二弹，燃情制作中！敬请期待!这仅仅是出发，绝......
              </div>
              <div class="day">2022年8月10日</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content3" @click="xwxqy">
              <!-- <div class="cpxx"> -->
              <img src="../../assets/cheshi.png" alt="" />
              <!-- </div> -->
              <div class="name"><span>仙剑奇侠传</span></div>
              <div class="sell">仙剑四主题卡组限量发售</div>
              <div class="brief">
                仙剑4第二弹，燃情制作中！敬请期待!这仅仅是出发，绝......
              </div>
              <div class="day">2022年8月10日</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content3" @click="xwxqy">
              <img src="../../assets/cheshi.png" alt="" />
              <div class="name"><span>仙剑奇侠传</span></div>
              <div class="sell">仙剑四主题卡组限量发售</div>
              <div class="brief">
                仙剑4第二弹，燃情制作中！敬请期待!这仅仅是出发，绝......
              </div>
              <div class="day">2022年8月10日</div>
            </div>
          </el-col>
        </el-row>
        <el-row class="xm1">
          <el-col :span="6">
            <div class="grid-content4" @click="xwxqy">
              <img src="../../assets/cheshi.png" alt="" />
              <div class="name"><span>仙剑奇侠传</span></div>
              <div class="sell">仙剑四主题卡组限量发售</div>
              <div class="brief">
                仙剑4第二弹，燃情制作中！敬请期待!这仅仅是出发，绝......
              </div>
              <div class="day">2022年8月10日</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content4" @click="xwxqy">
              <img src="../../assets/cheshi.png" alt="" />
              <div class="name"><span>仙剑奇侠传</span></div>
              <div class="sell">仙剑四主题卡组限量发售</div>
              <div class="brief">
                仙剑4第二弹，燃情制作中！敬请期待!这仅仅是出发，绝......
              </div>
              <div class="day">2022年8月10日</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content4" @click="xwxqy">
              <img src="../../assets/cheshi.png" alt="" />
              <div class="name"><span>仙剑奇侠传</span></div>
              <div class="sell">仙剑四主题卡组限量发售</div>
              <div class="brief">
                仙剑4第二弹，燃情制作中！敬请期待!这仅仅是出发，绝......
              </div>
              <div class="day">2022年8月10日</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 底部栏 -->
    <el-footer
      style="background-color: #414141; height: 3rem; "
      class="footerBox"
    >
      <div class="lll">
        <div class="gywn">关于我们</div>
        <div class="xxx">
          <img class="wb" src="../../assets/wb.png" alt="" />
          <img class="dy" src="../../assets/dy.png" alt="" />
          <img class="wx" src="../../assets/wx.png" alt="" />
        </div>
        <el-divider></el-divider>
      </div>
      <!-- 下面 -->
      <div class="aa">
        <div class="bb">
          <div class="relation">联系我们</div>
          <div class="cc">
            <div>福州卡乐动漫有限公司</div>
            <div>电话：XXXXXXXXXXX</div>
            <div>地址：XXXXXXXXXXXXXXXXXX</div>
          </div>
        </div>
        <!-- 第二部分 -->
        <div class="dd">
          <div class="vv" style="width: 109px; height: 109px">
            <img src="../../assets/lxtp.jpg" alt="" />
          </div>
          <div class="ii">
            <div>购买遇到问题？</div>
            <div>请扫左侧二维码迅速解决！</div>
          </div>
        </div>
        <!-- 第三部分 -->
        <div class="bottlog">
          <img class="an" src="../../assets/底部公司LOGO暗.png" alt="" />
          <img class="liang" src="../../assets/底部公司LOGO亮.png" alt="" />
        </div>
      </div>
      <div class="bah">
        福州卡乐动漫有限公司 版权所有<a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          >闽ICP备2022010038号-1</a
        >
      </div>
    </el-footer>
  </div>
</template>

<script>
import list1 from "@/assets/cheshi.png";
import list2 from "@/assets/cheshi.png";
import list3 from "@/assets/cheshi.png";
export default {
  data() {
    return {
      imgList: [
        {
          id: 0,
          idView: list1,
        },
        {
          id: 1,
          idView: list2,
        },
        {
          id: 2,
          idView: list3,
        },
      ],
      carouseId: 0,
    };
  },
  mounted() {
    this.setPY();
  },
  methods: {
    // 产品详情页跳转
    // cpxqy() {
    //   this.$router.push("./product");
    // },
    // xwxqy() {
    //   this.$router.push("./product");
    // },
    //轮播图间距设置
    carouselChange(now, old) {
      this.carouseId = now;
      this.setPY();
    },
    setPY() {
      // let _carouseId = this.carouseId;
      // if (_carouseId == 0) {
      //   $("#0").css("left", "-4%"); //中图片下就是向左移
      //   $("#0").next().css("left", "24%"); //右图片大就是进去
      //   $("#0").next().next().css("left", "-32%"); //左图片小就是向左移
      // } else if (_carouseId == 1) {
      //   $("#1").css("left", "-4%");
      //   $("#1").prev().css("left", "-32%");
      //   $("#1").next().css("left", "24%");
      // } else {
      //   $("#2").css("left", "-4%");
      //   $("#2").prev().css("left", "-32%");
      //   $("#2").prev().prev().css("left", "24%");
      // }
      let _carouseId = this.carouseId;
      if (_carouseId == 0) {
        $("#0").css("left", "5%"); //中图片下就是向左移
        $("#0").next().css("left", "18%"); //右图片大就是进去
        $("#0").next().next().css("left", "-8%"); //左图片小就是向左移
      } else if (_carouseId == 1) {
        $("#1").css("left", "5%");
        $("#1").prev().css("left", "-8%");
        $("#1").next().css("left", "18%");
      } else {
        $("#2").css("left", "5%");
        $("#2").prev().css("left", "-8%");
        $("#2").prev().prev().css("left", "18%");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 52px;
  display: flex;
  align-items: center;

  .left {
    margin: 20px 36px;
    img {
      margin-top: 20px;
      width: 100px;
    }
  }

  .header_right {
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    padding-right: 57px;

    .box2 {
      margin-left: 5px;
      text-align: center;
      padding: 0 10px 0;
      margin: 10px 0 0;
      color: #f23e44;
      height: 70px;
      position: relative;
      display: flex;
      justify-content: center;
      overflow: hidden;
      width: 80px;

      .text {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 79px;
        position: absolute;
        z-index: 1;
        .bott {
          font-size: 12px;
        }
        .top {
          font-size: 14px;
          font-weight: bold;
        }
      }
      .products {
        padding-left: 20px;
      }
      .bj {
        height: 80%;
        background: #f33d44;
        box-shadow: 0px 1px 0px 1px #ffffff;
        border-radius: 10px 10px 0px 0px;
        position: absolute;
        bottom: 0;
        width: 90%;
        bottom: -79px;
      }
    }
    .box2:hover {
      .text {
        .bott {
          color: #fff;
        }

        .top {
          color: #fff;
        }
      }

      .bj {
        animation-name: startYd;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
      }
    }
  }

  @keyframes startYd {
    0% {
      bottom: -79px;
    }

    100% {
      bottom: 0;
    }
  }
}

::v-deep .el-col-20 {
  margin-top: 20px;
  width: 80.33333%;
}

// 轮播图
// 按钮
::v-deep .el-carousel__indicators--outside {
  bottom: 0.13542rem;
  text-align: center;
  position: static;
  -webkit-transform: none;
  transform: none;
  background-color: #f7f7f8;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23dfdfdf' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
::v-deep .el-carousel__indicator.is-active button {
  widows: 11px;
  height: 11px;
  background-image: url("../../assets/lbtd2.png");
}
::v-deep .el-carousel__indicators--outside button {
  width: 11px;
  height: 11px;
  background-image: url("../../assets/lbtd1.png");
}

::v-deep .el-carousel__arrow--left {
  width: 50px;
  height: 50px;
  left: 270px;
  background-color: #8e949a;
  border: 2px solid #ffffff;
  background: rgba(65, 65, 65, 0.53);
  background-size: 100%;
}
::v-deep .el-carousel__arrow--left:hover {
  width: 50px;
  height: 50px;
  left: 270px;
  border: 2px solid #ffffff;
  background: rgba(243, 61, 68, 0.53);
  background-size: 100%;
}

::v-deep .el-carousel__arrow--right {
  width: 50px;
  height: 50px;
  right: 271px;
  border: 2px solid #ffffff;
  background: rgba(65, 65, 65, 0.53);
  background-size: 100%;
}
::v-deep .el-carousel__arrow--right:hover {
  width: 50px;
  height: 50px;
  right: 271px;
  border: 2px solid #ffffff;
  background: rgba(243, 61, 68, 0.53);
  background-size: 100%;
}

::v-deep .el-icon-arrow-left:before {
  content: "\e6de";
  font-size: 35px;
}

::v-deep .el-icon-arrow-right:before {
  content: "\e6e0";
  font-size: 35px;
}

::v-deep .el-carousel__container {
  height: 100%;
}

.el-carousel {
  margin-top: 22px;
  padding: 35px 0;
  background: #414141;
  height: 400px;
  box-sizing: border-box;
  overflow: hidden;
  background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 16c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zm33.414-6l5.95-5.95L45.95.636 40 6.586 34.05.636 32.636 2.05 38.586 8l-5.95 5.95 1.414 1.414L40 9.414l5.95 5.95 1.414-1.414L41.414 8zM40 48c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zM9.414 40l5.95-5.95-1.414-1.414L8 38.586l-5.95-5.95L.636 34.05 6.586 40l-5.95 5.95 1.414 1.414L8 41.414l5.95 5.95 1.414-1.414L9.414 40z' fill='%23636363' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
  .el-carousel__item {
    width: 800px;
    .lbt {
      width: 100%;
      height: 100%;
    }
  }

  .el-carousel__item:nth-child(2n + 1) {
    height: 300px;
    background-color: #d3dce6;
  }
  .el-carousel__item:nth-child(odd) {
    width: 800px;
    height: 306px;
    background-color: #d3dce6;
  }
  .el-carousel__item:nth-child(2n) {
    height: 300px;
    background-color: #99a9bf;
  }
}

::v-deep .el-carousel__item--card.is-active {
  width: 800px;
  height: 305px;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

// 产品板块
.cp {
  width: 1920px;
  height: 2100px;
  margin-top: -50px;
  padding-left: 298px;
  background-color: #f7f7f8;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23dfdfdf' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

  .cpxxx {
    width: 150px;
    height: 37px;
    font-size: 15px;
    background-image: url("../../assets/bioatii.png");
    background-size: 100%;

    h5 {
      margin-top: 86px;
      width: 60px;
      height: 19px;
      font-size: 15px;
      margin-left: 30px;
      padding-top: 8px;
      margin-top: 86px;
      font-weight: bold;
      color: #ffffff;
    }
  }

  .sm {
    margin: 0 -14px;

    .grid-content1 {
      padding-bottom: 71px;
      margin-bottom: 20px;
      margin-top: 23px;
      width: 415px;
      height: 256px;
      background-color: #fff;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
      img {
        width: 415px;
        height: 256px;
      }

      .xwbt {
        width: 218px;
        height: 20px;
        font-size: 20px;
        margin: 21px 24px 97px 100px;
        text-align: center;
        font-weight: bold;
        color: #323232;
      }
    }
  }

  .sm1 {
    margin: 0 -14px;
    .grid-content2 {
      padding-bottom: 71px;
      margin-bottom: 20px;
      margin-top: 23px;
      width: 415px;
      height: 256px;
      background-color: #fff;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);

      img {
        width: 415px;
        height: 256px;
      }

      .xwbt {
        width: 218px;
        height: 20px;
        font-size: 20px;
        margin: 21px 24px 97px 100px;
        text-align: center;
        font-weight: bold;
        color: #323232;
      }
    }
  }
}

// // 新闻板块
.xm {
  margin: 0 -13px;

  .grid-content3 {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 421px;
    height: 389px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);

    img {
      width: 421px;
      height: 215px;
    }

    .name {
      width: 80px;
      height: 16px;
      font-size: 16px;
      font-weight: 500;
      margin-top: 10px;
      margin-left: 25px;
      color: #ff5c42;
    }

    .sell {
      width: 200px;
      height: 18px;
      font-size: 18px;
      margin-top: 10px;
      margin-left: 25px;
      font-weight: bold;
      color: #323232;
    }

    .brief {
      width: 349px;
      height: 35px;
      font-size: 16px;
      font-weight: 400;
      margin-top: 20px;
      margin-left: 25px;
      color: #323232;
    }

    .day {
      height: 15px;
      font-size: 16px;
      font-weight: 500;
      margin-left: 25px;
      margin-top: 30px;
      color: #9b9b9b;
      white-space: nowrap;
    }
  }
}

.xm1 {
  margin: 0 -13px;

  .grid-content4 {
    width: 421px;
    height: 389px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);

    img {
      width: 421px;
      height: 215px;
    }

    .name {
      width: 80px;
      height: 16px;
      font-size: 16px;
      font-weight: 500;
      margin-top: 10px;
      margin-left: 25px;
      color: #ff5c42;
    }

    .sell {
      width: 200px;
      height: 18px;
      font-size: 18px;
      margin-top: 10px;
      margin-left: 25px;
      font-weight: bold;
      color: #323232;
    }

    .brief {
      width: 349px;
      height: 35px;
      font-size: 16px;
      font-weight: 400;
      margin-top: 20px;
      margin-left: 25px;
      color: #323232;
    }

    .day {
      height: 15px;
      font-size: 16px;
      font-weight: 500;
      margin-left: 25px;
      margin-top: 30px;
      color: #9b9b9b;
      white-space: nowrap;
    }
  }
}

.footerBox {
  background-color: #414141;
  position: relative;
  .lll {
    padding-left: 152px;

    .gywn {
      width: 64px;
      height: 15px;
      font-size: 16px;
      padding-top: 48px;
      padding-bottom: 40px;
      font-family: Noto Sans S Chinese;
      font-weight: 400;
      color: #ffffff;
      line-height: 50px;
    }
    .xxx {
      display: flex;
      img {
        margin-right: 21px;
      }
      .wb {
        width: 29px;
        height: 24px;
      }
      .dy {
        width: 23px;
        height: 29px;
      }
      .wx {
        width: 31px;
        height: 25px;
      }
    }
  }

  .aa {
    display: flex;

    .bb {
      margin-top: 30px;
      margin-left: 151px;

      .relation {
        width: 65px;
        height: 15px;
        font-size: 16px;
        margin-bottom: 24px;
        font-family: Noto Sans S Chinese;
        font-weight: 400;
        color: #ffffff;
        line-height: 50px;
      }

      .cc {
        width: 240px;
        height: 67px;
        font-size: 16px;
        font-weight: 300;
        color: #afafaf;
        line-height: 26px;
      }
    }

    .dd {
      display: flex;
      justify-content: flex-start;

      .vv {
        width: 109px;
        height: 109px;
        margin-left: 114px;
        margin-top: 35px;
        background: #ffffff;
        border-radius: 10px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .ii {
      width: 187px;
      height: 44px;
      font-size: 14px;
      font-weight: 400;
      margin-left: 21px;
      margin-top: 95px;
      color: #ffffff;
      line-height: 28px;
    }
    .bottlog {
      margin-left: 850px;
      margin-top: 79px;
      margin-right: 167px;

      .liang {
        display: none;
      }
    }

    .bottlog:hover,
    .bottlog:active {
      .an {
        display: none;
      }

      .liang {
        display: block;
      }
    }
  }
  .bah {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%);
  }
}

// 底部样式
::v-deep .el-divider--horizontal {
  width: 1599px;
}
</style>
