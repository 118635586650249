<template>
<div>产品详情页</div>
</template>

<script>
export default {};
</script>

<style>

</style>